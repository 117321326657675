import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { locale, t } from '../../../src/common/i18n'
import {
  getShortCompany,
  getFullCompany,
} from '../../../src/common/utils/getCompany'

const ImageBateria = 'https://media.rodi.es/landings/moto/bateria-moto.jpg'
const ImageDisco =
  'https://media.rodi.es/landings/moto/disco-freno-rueda-delantera-moto.jpg'
const ImageEvento =
  'https://media.rodi.es/landings/moto/evento-rodibook-moto.jpg'
const ImageKit = 'https://media.rodi.es/landings/moto/kit-transmision-moto.jpg'
const ImageMecanico =
  'https://media.rodi.es/landings/moto/mecanico-cambio-aceite-revision-moto.jpg'
const ImageFiltro =
  'https://media.rodi.es/landings/moto/moto-custom-filtro-aire.jpg'
const ImageEmbrague =
  'https://media.rodi.es/landings/moto/reparacion-embrague-moto.jpg'
const ImageRueda =
  'https://media.rodi.es/landings/moto/rueda-neumatico-moto.jpg'

interface Props {
  styles: { [k: string]: string }
}

const sectionsEs = [
  {
    title: 'SERVICIOS DE MECÁNICA',
    items: [
      {
        title: 'Frenos de Moto',
        description: `Entender cómo funcionan los frenos de tu moto te ayudará a saber la mejor forma
         de sacarles el máximo partido. Te lo explicamos aquí.`,
        img: ImageDisco,
        route: 'moto.frenos',
      },
      {
        title: 'Kit de Transmisión',
        description: `Transmite la potencia del motor a la rueda trasera de tu moto. 
        Compuesto por la cadena, el piñón y el plato. Encuentra toda la información aquí`,
        img: ImageKit,
        route: 'moto.kits',
      },
      {
        title: 'Embrague de Moto',
        description: `Es el encargado de coordinar las marchas de tu moto con el giro del motor. 
        En esta sección te explicamos cómo funciona.`,
        img: ImageEmbrague,
        route: 'moto.embragues',
      },
    ],
  },
  {
    title: 'SERVICIOS DE MANTENIMIENTO',
    items: [
      {
        title: 'Filtros de Moto',
        description: `Un elemento esencial para el motor de tu moto que en ocasiones pasa desapercibido. 
        Conoce los tipos de filtro que existen y para qué sirve cada uno.`,
        img: ImageFiltro,
        route: 'moto.filtros',
      },
      {
        title: 'Batería de Moto',
        description: `Es la responsable de aportar la energía necesaria para el arranque del motor. 
        Te explicamos qué es, cómo funciona y los diferentes tipos de batería que existen.`,
        img: ImageBateria,
        route: 'moto.baterias',
      },
      {
        title: 'Neumáticos de Moto',
        description: `Son los responsables de mantenerte agarrado al asfalto en cada curva. 
        Los neumáticos son una pieza clave de seguridad para tu moto.`,
        img: ImageRueda,
        route: 'neumaticos.todo_sobre',
      },
      {
        title: 'Plan de Revisiones',
        description: `¿Necesitas pasar la revisión a tu moto? En Rodi podemos ayudarte. 
        Solicita cita previa en un centro Rodi especializado en moto y déjate asesorar por un técnico especialista.`,
        img: ImageMecanico,
        route: 'revisiones.index',
      },
    ],
  },
  {
    title: 'EVENTOS DE MOTOS',
    items: [
      {
        title: 'RodiBook',
        description: `Una ruta en moto por el Pirineo con un recorrido “sorpresa” que descubres solo con la 
        ayuda de un Roadbook.  Un evento pensado por moteros para moteros.`,
        img: ImageEvento,
        route: 'moto.rodibook',
      },
    ],
  },
]

const sectionCa = [
  {
    title: 'SERVEIS DE MECÀNICA',
    items: [
      {
        title: 'Frens de Moto',
        description: `Entendre com funcionen els frens de la teva moto t'ajudarà a saber la millor 
        manera de treure'n el màxim partit. T'ho expliquem aquí.`,
        img: ImageDisco,
        route: 'moto.frenos',
      },
      {
        title: 'Kit de Transmissió',
        description: `Transmet la potència del motor a la roda del darrere de la teva moto. 
        Compost per la cadena, el pinyó i el plat. Troba tota la informació aquí.`,
        img: ImageKit,
        route: 'moto.kits',
      },
      {
        title: 'Embragatge de la Moto',
        description: `És l'encarregat de coordinar les marxes de la teva motocicleta amb el gir del motor. 
        En aquesta secció t'expliquem com funciona.`,
        img: ImageEmbrague,
        route: 'moto.embragues',
      },
    ],
  },
  {
    title: 'SERVEIS DE MANTENIMENT',
    items: [
      {
        title: 'Filtres de Moto',
        description: `Un element essencial per al motor de la teva moto que de vegades passa desapercebut. 
        Coneix els tipus de filtre que existeixen i per a què serveix cadascun.`,
        img: ImageFiltro,
        route: 'moto.filtros',
      },
      {
        title: 'Bateria de Moto',
        description: `És la responsable d'aportar l'energia necessària per a l'arrencada del motor. 
        T'expliquem què és, com funciona i els diferents tipus de bateria que existeixen.`,
        img: ImageBateria,
        route: 'moto.baterias',
      },
      {
        title: 'Pneumàtics de Moto',
        description: `Són els responsables de mantenir-te agafat a l'asfalt a cada revolt. 
        Els pneumàtics són una peça clau de seguretat per a la teva moto.`,
        img: ImageRueda,
        route: 'neumaticos.todo_sobre',
      },
      {
        title: 'Pla de Revisions',
        description: `Necessites passar la revisió a la teva moto? A Rodi podem ajudar-te. 
        Demana cita prèvia a un centre Rodi especialitzat en moto i deixat assessorar per un tècnics especialistes.`,
        img: ImageMecanico,
        route: 'revisiones.index',
      },
    ],
  },
  {
    title: 'ESDEVENIMENTS DE MOTOS',
    items: [
      {
        title: 'RodiBook',
        description: `Una ruta en moto pel Pirineu amb un recorregut "sorpresa" que descobreixes només 
        amb l'ajuda d'un Roadbook. Un esdeveniment pensat per motoristes per a motoristes.`,
        img: ImageEvento,
        route: 'moto.rodibook',
      },
    ],
  },
]

const MotoCa = ({ styles }: Props) => (
  <LayoutLanding
    title="Serveis per a la teva moto"
    textRecomendation={t('recomendaciones.moto')}>
    <React.Fragment>
      <p>
        A <strong> Rodi Motor Services </strong> ens encarreguem del manteniment
        de la teva moto. Els nostres serveis t’ofereixen solucions de mecànica i
        posada a punt com: manteniment del sistema de frenada, kit de
        transmissió, bateries, canvi d'oli, filtres... Als tallers Rodi trobaràs
        una solució de manteniment adaptada a les teves necessitats i a les de
        la teva motocicleta.
      </p>
      <p>
        A continuació, trobaràs tots els serveis que oferim per a la teva moto.
        Dins de cada secció, t'expliquem què és cada element i la seva
        importància per a què la teva moto es mantingui sempre en un estat
        òptim.
      </p>
      {sectionCa.map(section => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const MotoEs = ({ styles }: Props) => (
  <LayoutLanding
    title="Servicios para tu moto"
    textRecomendation={t('recomendaciones.moto')}>
    <React.Fragment>
      <p>
        En <strong>{getFullCompany()} </strong>nos encargamos del
        <strong> mantenimiento de tu moto</strong>. Nuestros servicios se
        centran en ofrecer soluciones de <strong>mecánica</strong> y
        <strong> puesta a punto </strong>
        como: mantenimiento del sistema de frenado, kit de transmisión,
        baterías, cambio de aceite, filtros…  En los talleres{' '}
        {getShortCompany()} encontrarás una solución de mantenimiento adaptada a
        tus necesidades y a las de tu motocicleta.
      </p>
      <p>
        A continuación, encontrarás todos los servicios que ofrecemos para tu
        moto. Dentro de cada sección, te explicamos qué es cada elemento y su
        importancia para que tu moto se mantenga siempre en un estado óptimo.
      </p>
      {sectionsEs.map(section => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const MotoContents = ({ ...props }: Props) =>
  locale === 'es' ? <MotoEs {...props} /> : <MotoCa {...props} />

export default MotoContents
